import { Grid, Paper, Typography } from "@mui/material";
import "@fontsource/roboto/400.css";
export default function MyPaper() {
  return (
    <div style={{ width: "80%", margin: "auto auto 50px" }}>
      <Grid container>
        <Grid item xs={12}>
          <Paper elevation={4} sx={{ padding: 5 }}>
            <div>
              <Typography variant="h4" gutterBottom>
                 Amazon Virtual Private Cloud (Amazon VPC)
              </Typography>
              <img
                src="https://d2908q01vomqb2.cloudfront.net/da4b9237bacccdf19c0760cab7aec4a8359010b0/2018/05/14/ddb-no-vpc-endpoint-1024x561.png"
                alt="vpc Logo"
                width={100}
                height={100}
              />
              <Typography variant="h6" gutterBottom textAlign={"left"}>
                <strong>
                A networking service that you can use to establish boundaries around your AWS resources
                </strong>
              </Typography>
              <Typography variant="body1" paragraph textAlign={"left"}>
              Amazon VPC enables you to provision an isolated section of the AWS Cloud. 
              In this isolated section, you can launch resources in a virtual network 
              that you define.Within a virtual private cloud (VPC), you can organize your 
              resources into subnets. A subnet is a section of a VPC that can contain resources 
              such as Amazon EC2 instances.
                
              </Typography>
              
              <Typography variant="title" color="inherit">
                <a href="./VPC.pdf" target="_blank">
                  Continue reading...
                </a>
              </Typography>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
