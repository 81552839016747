import { Box, Grid } from "@mui/material";
import WorkCard from "./WorkCard";

export default function Portfolio() {
  return (
    <div
      style={{ margin: "50px auto", backgroundColor: "#F7F7F7", width: "100%" }}
    >
      <Box sx={{border:'1px solid #F7F7F7'}} padding={5} >
        <Grid
          container
          rowSpacing={5}
          justifySelf={'center'}
          marginLeft={5}
        >
          <Grid item xs={12} md={4} sm={6}>
            <WorkCard {...{Title:'Online Bookshop',Desc:"Developed online bookshop, using MERN stack (MongoDB,Express,React,NodeJS).",img:'https://www.servermania.com/kb/wp-content/uploads/2022/04/KB-MERN-Featured.jpg'}}/>
          </Grid>
          <Grid item xs={12} md={4} sm={6} >
            <WorkCard {...{Title:'Resturant managment app',Desc:"Built Resturant inventroy management application using technologies such as ReactNative, Express, MVC, MongoDB, JWT",img:'https://www.ideamotive.co/hubfs/React.js%20vs.%20React%20Native_%20Which%20One%20And%20When%20To%20Choose_%20-%20hero%20%281%29.png'}} />
          </Grid>
          <Grid item xs={12} md={4} sm={6} >
            <WorkCard {...{Title:'Goal Planner',Desc:"Designed to track personal goals. Technologies Used: MEAN Stack (MongoDB,Express, Angular, NodeJS)",img:'https://repository-images.githubusercontent.com/24195339/87018c00-694b-11e9-8b5f-c34826306d36'}}/>
          </Grid>
          <Grid item xs={12} md={4} sm={6} >
            <WorkCard {...{Title:'Personal Profile',Desc:"Built functional web based personal profile. Technologies Used: HTML5, CSS, JavaScript, React, Material UI,AWS S3, Route 53, CloudFront, API Gateway, Lambda, DynamoDB, SNS, SQS ",img:'https://intellipaat.com/blog/wp-content/uploads/2022/01/image-134.png'}}/>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
