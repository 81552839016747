import * as React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import LaptopMacIcon from "@mui/icons-material/LaptopMac";
import Typography from "@mui/material/Typography";
import CodeIcon from '@mui/icons-material/Code';
import BuildIcon from '@mui/icons-material/Build';

export default function CustomizedTimeline() {
  return (
    <Timeline position="alternate">
      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: "auto 0" }}
          align="right"
          variant="body2"
          color="text.secondary"
        >
          2018 -2019
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot>
            <BuildIcon />
          </TimelineDot>
          <TimelineConnector sx={{ bgcolor: "#14F5C0" }}/>
        </TimelineSeparator>
        <TimelineContent sx={{ py: "50px", px: 2 }}>
          <Typography variant="h6" component="span">
            General Mills
          </Typography>
          <Typography>IT Support</Typography>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: "auto 0" }}
          variant="body2"
          color="text.secondary"
        >
          2019 - 2021
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector sx={{ bgcolor: "#14F5C0" }}/>
          <TimelineDot color="primary">
            <LaptopMacIcon />
          </TimelineDot>
          <TimelineConnector sx={{ bgcolor: "#035DE8" }}/>
        </TimelineSeparator>
        <TimelineContent sx={{ py: "50px", px: 2 }}>
          <Typography variant="h6" component="span">
          General Mills
          </Typography>
          <Typography> Software developer</Typography>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
      <TimelineOppositeContent
          sx={{ m: "auto 0" }}
          variant="body2"
          color="text.secondary"
        >
          2021 - 2022
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector sx={{ bgcolor: "#035DE8" }} />
          <TimelineDot color="primary" variant="outlined">
            <CodeIcon />
          </TimelineDot>
          <TimelineConnector sx={{ bgcolor: "secondary.main" }} />
        </TimelineSeparator>
        <TimelineContent sx={{ py: "50px", px: 2 }}>
          <Typography variant="h6" component="span">
            Maharishi International University
          </Typography>
          <Typography>Master's in Software Developement</Typography>
        </TimelineContent>
      </TimelineItem>
      
    </Timeline>
  );
}
