import { Avatar } from "@mui/material";

export default function Welcome() {
  return (
    <div
      style={{ width: "50%", margin: "200px auto 400px", lineHeight: "2em" }}
    >
      <Avatar
        alt="Nebeyu"
        src="./neb.jpg"
        sx={{ width: 200, height: 200, display:'inline-block' }}
      />
      <h3>Hello, I am Nebeyu </h3>
      <h1>Full Stack JavaScript Web Developer</h1>
      <p>
        Full Stack JavaScript Developer with a Master's degree form Mahirishi 
        international university. I have strong backgroud both in frontend and 
        backend applications. I have worked on many projects using technologies
        such as JavaScript, React, ReactNative , Angular, NodeJS, MySQL, MongoDB.
      </p>
    </div>
  );
}
