// import SendSharpIcon from "@mui/icons-material/SendSharp";
import { Alert, Button, Grid, TextField } from "@mui/material";
import { Box, padding } from "@mui/system";
import axios from "axios";
import { useState } from "react";
export default function ContactMe() {
  const [sent, setSent] = useState("initial");
  const [emptyEmail, setEmptyEmail] = useState(false);
  const [emptyFullName, setEmptyFullName] = useState(false);
  const [emptyMessage, setEmptyMessage] = useState(false);
  const [emptyPhone, setEmptyPhone] = useState(false);
  const [emptyMessageTitle, setEmptyMessageTitle] = useState(false);

  const success = (
    <Alert severity="success">Thank you for contacting me </Alert>
  );
  const fail = (
    <Alert severity="error">
      There seems to be a problem while sending your request
    </Alert>
  );

  const handleSubmit =  (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    if (
      data.get("email") !== "" &&
      data.get("fullName") !== "" &&
      data.get("message") !== "" &&
      data.get("messagetitle") !== "" &&
      data.get("phone") !== ""
    ) {
      setEmptyEmail(false);
      setEmptyFullName(false);
      setEmptyMessage(false);
      setEmptyPhone(false);
      setEmptyMessageTitle(false);
      console.log(data.get("fullName"));
      let testData = {
        FullName: data.get("fullName"),
        Email: data.get("email"),
        Phone: data.get("phone"),
        MessageTitle : data.get("messagetitle"),
        Message: data.get("message")
       
      };
      console.log(testData)
      axios({
        method: 'post',
        url: "https://1uflc7jfbj.execute-api.us-east-1.amazonaws.com/v1/contact",
        data: {
          FullName: data.get("fullName"),
            Email: data.get("email"),
            Phone: data.get("phone"),
            MessageTitle : data.get("messagetitle"),
            Message: data.get("message")
        }
      })
        // .post(
        //   "https://1uflc7jfbj.execute-api.us-east-1.amazonaws.com/v1/contact",testData
        //   // {
        //   //   FullName: data.get("fullName"),
        //   //   Email: data.get("email"),
        //   //   Phone: data.get("phone"),
        //   //   MessageTitle : data.get("messagetitle"),
        //   //   Message: data.get("message")
           
        //   // }
        // )
        .then((res) => {
          console.log(res);
          setSent("sent");
        })
        .catch((err) => {
          console.log(err);
          setSent("fail");
        });
    } else {
      data.get("email") ? setEmptyEmail(false) : setEmptyEmail(true);
      data.get("fullName") ? setEmptyFullName(false) : setEmptyFullName(true);
      data.get("message") ? setEmptyMessage(false) : setEmptyMessage(true);
      data.get("messagetitle")? setEmptyMessageTitle(false):setEmptyMessageTitle(true);
      data.get("phone")? setEmptyPhone(false):setEmptyPhone(true);
    }

    console.log("result of form", data.get("fullName"))
  };
  return (
    <div>
      <Grid container spacing={2}>
        
        <Grid item xs={12} md={5} sm={12} lg={6}>
          <div style={{ height: "70vh" }}>
            <Box
              sx={{
                marginTop: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box
                component="form"
                onSubmit={handleSubmit}
                noValidate
                sx={{ mt: 1, padding: 5 }}
              >
                {emptyFullName ? (
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="fullName"
                    label="Full Name"
                    name="fullName"
                    autoComplete="FullName"
                    error
                    helperText="Please Fill in your Full Name"
                  />
                ) : (
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="fullName"
                    label="Full Name"
                    name="fullName"
                    autoComplete="FullName"
                  />
                )}
                {emptyEmail ? (
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    error
                    helperText="Please Fill in your email"
                  />
                ) : (
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                  />
                )}
                {emptyPhone ? (
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="phone"
                    label="Phone"
                    id="phone"
                    multiline
                  
                    error
                    helperText="Please enter your phone"
                  />
                ) : (
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="phone"
                    label="Phone"
                    id="phone"
                    multiline
                  
                  />
                )
                }
                {emptyMessageTitle ? (
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="messagetitle"
                    label="Message Title"
                    id="messagetitle"
                    multiline
                  
                    error
                    helperText="Please enter your message title"
                  />
                ) : (
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="messagetitle"
                    label="Message Title"
                    id="messagetitle"
                    multiline
                  
                  />
                )
                }
                
                {emptyMessage ? (
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="message"
                    label="Message"
                    id="message"
                    multiline
                    rows={4}
                    error
                    helperText="Please enter your message"
                  />
                ) : (
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="message"
                    label="Message"
                    id="message"
                    multiline
                    rows={4}
                  />
                )
                }

                {sent == "sent" ? success : sent == "fail" ? fail : null}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Contact Me
                </Button>
              </Box>
            </Box>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
